import { WordPressTemplate } from '@faustwp/core/dist/mjs/components/WordPressTemplate';
import { getWordPressProps } from '@faustwp/core/dist/mjs/getWordPressProps';
import { GetStaticProps } from 'next';
import { WordPressTemplateProps } from '@/types';

const Page = ( props: WordPressTemplateProps ) => {
	return <WordPressTemplate { ...props } />;
};

export const getStaticProps: GetStaticProps = ( ctx ) => {
	return getWordPressProps( { ctx } );
};

export default Page;
